#main > .row::before,
#main > .row::after {
  content: '';
  display: table;
}

#main > .row::after {
  clear: both;
}

.btn,
a.btn {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.nav-links {
  background: linear-gradient(to top, #e44b32, #f6634a);
  font-size: 0;
  padding: 2px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}

.nav-links a,
.nav-links span {
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  background: none;
  text-align: center;
  text-decoration: none;
  margin: 0 1px;
}

.nav-links a.prev,
.nav-links a.next,
.nav-links span.prev,
.nav-links span.next {
  width: 92px;
  background: none;
  color: #fff;
}

.nav-links span {
  color: #191919;
  background: #fff;
}

/* reset browser styles */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1.2;
}

ol {
  padding-left: 16px;
  list-style: decimal;
}

ul {
  padding-left: 16px;
  list-style: disc;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* end reset browser styles */

* {
  outline: none;
}

body {
  font-family: "Glober", sans-serif;
  color: #191919;
  font-size: 14px;
  background: #FFF;
  position: relative;
}

a {
  color: #185ea2;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

i {
  font-style: normal;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ul.ul-reset,
.ul-reset ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

::-moz-placeholder {
  opacity: 1;
}

::-webkit-input-placeholder {
  opacity: 1;
}

button {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
}

#main #content {
  float: left;
  width: 100%;
}

#main #content .content-in {
  margin-right: 300px;
}

#main #sidebar {
  float: left;
  width: 270px;
  margin-left: -270px;
}

#main #view-2 #content .content-in {
  margin: 0 0 0 300px;
}

#main #view-2 #sidebar {
  margin-left: -100%;
}

.btn,
a.btn {
  font-size: 14px;
  font-family: "Glober", sans-serif;
  border: none;
  line-height: 41px;
  height: 41px;
  color: #FFF;
  border-radius: 3px;
  text-decoration: none;
}

.btn.btn-red,
a.btn.btn-red {
  background: linear-gradient(to top, #e44b32, #f6634a);
}

.btn.btn-red:hover,
a.btn.btn-red:hover {
  background: linear-gradient(to bottom, #e44b32, #f6634a);
}

.btn.btn-blue,
a.btn.btn-blue {
  background: #0483d2;
}

.btn.btn-blue:hover,
a.btn.btn-blue:hover {
  background: #0364a0;
}

form {
  font-size: 0;
}

form input[type=text],
form input[type=email],
form textarea,
form select {
  width: 100%;
  background: #fff;
  border-radius: 3px;
  border: none;
  height: 41px;
  padding: 0 17px;
  font-size: 14px;
  font-family: "Glober", sans-serif;
  vertical-align: middle;
}

form textarea {
  height: 100px;
  resize: none;
  padding: 10px 17px;
}

form .label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.title,
h1,
h2,
h3,
h4,
.title-2 {
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 15px;
}

h2,
.title-2 {
  font-size: 29px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 27px;
}

.subtitle {
  margin-top: -5px;
  margin-bottom: 15px;
  font-size: 18px;
}

.arr-prev,
.arr-next {
  vertical-align: middle;
  width: 32px;
  height: 32px;
  display: inline-block;
}

.arr-prev {
  background: url("../img/arr-prev.png") no-repeat;
}

.arr-prev:hover {
  background: url("../img/arr-prev-hov.png") no-repeat;
}

.arr-next {
  background: url("../img/arr-next-hov.png") no-repeat;
}

.path {
  margin-bottom: 30px;
}

.path ul {
  font-size: 0;
}

.path ul li {
  display: inline-block;
  background: url("../img/separator.png") no-repeat right;
  padding-right: 10px;
  margin-right: 6px;
  font-size: 12px;
}

.path ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  background: none;
}

.arrs {
  font-size: 0;
  display: none;
  margin-top: 30px;
}

@media (max-width: 1199px) {
  .container {
    width: 760px;
  }

  #main #content .content-in {
    margin-right: 260px;
  }

  #main #sidebar {
    width: 245px;
    margin-left: -245px;
  }

  #main #view-2 #content .content-in {
    margin: 0 0 0 260px;
  }

  .title,
  h1,
  h2,
  h3,
  h4,
  .title-2 {
    font-size: 22px;
  }

  h2,
  .title-2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  .subtitle {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .nav-links {
    display: none;
  }

  .nav-links a.prev,
  .nav-links a.next,
  .nav-links span.prev,
  .nav-links span.next {
    display: block;
    width: auto;
  }

  .container {
    width: 290px;
  }

  #main #content {
    float: none;
  }

  #main #content .content-in {
    margin-right: 0;
  }

  #main #sidebar {
    display: none;
  }

  #main #view-2 #content .content-in {
    margin: 30px 0 0 0;
  }

  #main #view-2 #sidebar {
    display: block;
    float: none;
    width: auto;
    margin: 30px 0 0 0;
  }

  .title,
  h1,
  h2,
  h3,
  h4,
  .title-2 {
    text-align: center;
  }

  .path ul li {
    line-height: 20px;
  }

  .arrs {
    display: block;
  }

  .arrs a {
    margin: 0 4px;
  }
}
#article::before,
#article::after {
  content: '';
  display: table;
}

#article::after {
  clear: both;
}

#article {
  margin-bottom: 50px;
}

#article > figure {
  margin-right: 27px;
  float: left;
  width: 168px;
}

#article p {
  margin-bottom: 16px;
  font-size: 15px;
}

#article p:last-child {
  margin-bottom: 0;
}

#article em {
  color: #747474;
  font-family: Georgia, "Century Schoolbook L", Serif;
  font-size: 14px;
}

#article:last-child {
  margin-bottom: 0;
}

#article > ul {
  padding: 0 0 0 20px;
  margin: 0 0 20px 0;
  list-style: none;
}

#article > ul li {
  margin-bottom: 10px;
  padding-left: 15px;
  position: relative;
}

#article > ul li:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  left: 0;
  top: 8px;
  background: #ed573e;
  border-radius: 50%;
}

#article > ul li:last-child {
  margin-bottom: 0;
}

#article > ul:last-child {
  margin-bottom: 0;
}

#article table {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #185ea2;
  margin-bottom: 20px;
}

#article table:last-child {
  margin-bottom: 0;
}

#article table tr td {
  height: 41px;
  padding: 0 15px;
  vertical-align: middle;
}

#article table tr:first-child td {
  font-weight: bold;
}

#article table tr:nth-child(even) {
  background: #f7f9fa;
}

#article .alignleft {
  float: left;
  margin-right: 29px;
}

#article .alignright {
  float: right;
  margin-left: 29px;
}

#article blockquote {
  background: #f7f9fa;
  padding: 25px 25px 25px 131px;
  position: relative;
  margin-bottom: 20px;
  line-height: 20px;
  border-radius: 3px;
  font-size: 14px;
}

#article blockquote:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  background: url("../img/icon-tick.png") no-repeat;
  top: 20px;
  left: 43px;
}

#article blockquote:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  #article > figure {
    width: auto;
    margin-right: 0;
    float: none;
    margin-bottom: 16px;
  }

  #article > figure img {
    width: 100%;
  }

  #article img.alignleft,
  #article img.alignright {
    float: none;
    margin: 0 0 20px 0;
    width: 100%;
  }

  #article blockquote {
    padding: 15px;
  }

  #article blockquote:before {
    display: none;
  }
}
#articles .item::before,
#articles .item::after {
  content: '';
  display: table;
}

#articles .item::after {
  clear: both;
}

#articles .item {
  margin-bottom: 27px;
}

#articles .item figure {
  width: 68px;
  float: left;
  font-size: 0;
}

#articles .item figure img {
  border-radius: 5px;
}

#articles .item > a {
  display: block;
  margin-left: 80px;
  font-size: 12px;
  line-height: 20px;
}
#banners .item .item-in::before,
#banners .item .item-in::after {
  content: '';
  display: table;
}

#banners .item .item-in::after {
  clear: both;
}

#banners {
  margin-bottom: 50px;
  position: relative;
  margin-left: -31px;
}

#banners .item {
  padding-left: 31px;
}

#banners .item .item-in {
  background: #f8f4ea;
  position: relative;
  border-radius: 3px;
}

#banners .item .item-in figure {
  float: left;
  width: 477px;
  font-size: 0;
  border-radius: 3px 0 0 3px;
  overflow: hidden;
}

#banners .item .item-in .item-body {
  margin-left: 477px;
  padding: 28px 0 0 28px;
}

#banners .item .item-in .item-body p {
  line-height: 20px;
  margin-bottom: 15px;
  padding-right: 28px;
}

#banners .item .item-in .item-body p.red {
  color: #e85138;
}

#banners .item .item-in .item-body .btn-free {
  position: relative;
  width: 218px;
  padding-left: 15px;
  text-align: left;
}

#banners .item .item-in .item-body .btn-free:after {
  position: absolute;
  content: "";
  width: 36px;
  height: 41px;
  background: url("../img/corner.png") no-repeat;
  right: -18px;
}

#banners .item .item-in .item-body .btn-free:before {
  position: absolute;
  content: "";
  width: 11px;
  height: 11px;
  background: url("../img/arr-free.png") no-repeat;
  top: 16px;
  right: 11px;
  z-index: 1;
}

#banners .item .item-in .item-body .btn-free:hover:after {
  background: url("../img/corner-hov.png") no-repeat;
}

#banners .item .item-in .sticker {
  background: url("../img/sticker-price.png") 0 0 no-repeat;
  width: 245px;
  height: 70px;
  position: absolute;
  left: -31px;
  bottom: 21px;
  padding: 8px 0 0 94px;
  color: #fff;
}

#banners .item .item-in .sticker span {
  font-size: 12px;
}

#banners .item .item-in .sticker .price {
  font-size: 0;
}

#banners .item .item-in .sticker .price b {
  display: inline-block;
  font-size: 30px;
  vertical-align: middle;
  margin-right: 8px;
}

#banners .item .item-in .sticker .price .currency {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}

#banners .item .item-in .sticker:before {
  content: '';
  background: url("../img/icon-price.png") 0 center no-repeat;
  width: 52px;
  height: 47px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

#banners .arr-prev,
#banners .arr-next {
  position: absolute;
  bottom: 36px;
}

#banners .arr-next {
  right: 26px;
}

#banners .arr-prev {
  right: 67px;
}

@media (max-width: 1199px) {
  #banners {
    margin-left: -20px;
  }

  #banners .item {
    padding-left: 20px;
  }

  #banners .item .item-in figure {
    width: 180px;
  }

  #banners .item .item-in figure img {
    width: auto;
    max-width: none;
    margin-left: -70px;
    height: 231px;
  }

  #banners .item .item-in .item-body {
    margin-left: 180px;
    padding: 15px;
  }

  #banners .item .item-in .item-body p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  #banners .item .item-in .item-body p {
    padding-right: 15px;
  }

  #banners .item .item-in .item-body .btn-free {
    width: 170px;
    padding-left: 10px;
    font-size: 12px;
  }

  #banners .item .item-in .item-body .btn-free:before {
    display: none;
  }

  #banners .item .item-in .sticker {
    background: url("../img/sticker-price-tab.png") no-repeat;
    width: 178px;
    height: 61px;
    padding: 4px 0 0 30px;
    left: -20px;
    top: 160px;
    bottom: auto;
  }

  #banners .item .item-in .sticker:before {
    display: none;
  }

  #banners .arr-prev,
  #banners .arr-next {
    bottom: 15px;
  }

  #banners .arr-next {
    right: 21px;
  }

  #banners .arr-prev {
    right: 62px;
  }
}

@media (max-width: 767px) {
  #banners {
    margin: 0 -15px 30px -15px;
  }

  #banners .item {
    padding: 0;
  }

  #banners .item .item-in figure {
    width: auto;
    float: none;
  }

  #banners .item .item-in figure img {
    margin-left: 0;
    width: 100%;
  }

  #banners .item .item-in .item-body {
    margin: 0;
    text-align: center;
  }

  #banners .item .item-in .sticker {
    left: auto;
    bottom: auto;
    right: 0;
    top: 150px;
  }

  #banners .arr-prev,
  #banners .arr-next {
    bottom: 20px;
  }
}
#blog .item::before,
#blog .item::after {
  content: '';
  display: table;
}

#blog .item::after {
  clear: both;
}

#blog {
  margin-bottom: 40px;
}

#blog .item {
  margin-bottom: 40px;
}

#blog .item figure {
  float: left;
  width: 208px;
  font-size: 0;
}

#blog .item figure img {
  border-radius: 5px;
}

#blog .item .item-body {
  margin-left: 240px;
}

#blog .item .item-body p {
  line-height: 20px;
  margin-bottom: 10px;
}

#blog .item:last-child {
  margin-bottom: 0;
}

a.btn-more {
  display: none;
  width: 100%;
  font-weight: bold;
}

@media (max-width: 1199px) {
  #blog .item figure {
    width: 178px;
  }

  #blog .item .item-body {
    margin-left: 192px;
  }

  #blog .item .item-body p {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  #blog .item figure {
    width: auto;
    float: none;
    margin-bottom: 12px;
  }

  #blog .item figure img {
    width: 100%;
  }

  #blog .item .item-body {
    margin: 0;
    text-align: center;
  }

  a.btn-more {
    display: block;
  }
}
#docs {
  position: relative;
  font-size: 0;
  text-align: center;
  margin-bottom: 50px;
  border: 1px solid #f7f9fa;
  border-radius: 3px;
  padding: 20px 0;
}

#docs .item {
  width: 160px;
  margin: 0  auto;
}

#docs .item a {
  display: block;
  margin-bottom: 5px;
}

#docs .item a img {
  display: inline-block;
}

#docs b {
  font-size: 20px;
  display: block;
}

#docs .arr-prev,
#docs .arr-next {
  position: absolute;
  top: 100px;
}

#docs .arr-prev {
  left: 14px;
}

#docs .arr-next {
  right: 14px;
}

@media (max-width: 1199px) {
  #docs b {
    font-size: 16px;
  }
}
/* This stylesheet generated by Transfonter (https://transfonter.org) on May 4, 2017 1:04 PM */

@font-face {
  font-family: 'Glober';
  src: url("../fonts/subset-GloberBold.eot");
  src: url("../fonts/subset-GloberBold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-GloberBold.woff") format("woff"), url("../fonts/subset-GloberBold.ttf") format("truetype"), url("../fonts/subset-GloberBold.svg#GloberBold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Glober';
  src: url("../fonts/subset-GloberRegular.eot");
  src: url("../fonts/subset-GloberRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-GloberRegular.woff") format("woff"), url("../fonts/subset-GloberRegular.ttf") format("truetype"), url("../fonts/subset-GloberRegular.svg#GloberRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glober';
  src: url("../fonts/subset-Glober-SemiBold.eot");
  src: url("../fonts/subset-Glober-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Glober-SemiBold.woff") format("woff"), url("../fonts/subset-Glober-SemiBold.ttf") format("truetype"), url("../fonts/subset-Glober-SemiBold.svg#Glober-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Glober';
  src: url("../fonts/subset-Glober-Light.eot");
  src: url("../fonts/subset-Glober-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Glober-Light.woff") format("woff"), url("../fonts/subset-Glober-Light.ttf") format("truetype"), url("../fonts/subset-Glober-Light.svg#Glober-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Glober';
  src: url("../fonts/subset-GloberHeavy.eot");
  src: url("../fonts/subset-GloberHeavy.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-GloberHeavy.woff") format("woff"), url("../fonts/subset-GloberHeavy.ttf") format("truetype"), url("../fonts/subset-GloberHeavy.svg#GloberHeavy") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Glober Book';
  src: url("../fonts/subset-GloberBook.eot");
  src: url("../fonts/subset-GloberBook.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-GloberBook.woff") format("woff"), url("../fonts/subset-GloberBook.ttf") format("truetype"), url("../fonts/subset-GloberBook.svg#GloberBook") format("svg");
  font-weight: normal;
  font-style: normal;
}
#footer {
  background: #0483d2;
  margin-top: 50px;
}

#footer .container {
  position: relative;
  height: 115px;
}

#footer .container .logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  line-height: 20px;
  width: 100px;
}

#footer .container .logo a {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}

#footer .container .tel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  text-align: right;
  color: #fff;
}

#footer .container .tel b {
  font-size: 24px;
}

#footer .container .tel a {
  font-size: 16px;
  color: #fff;
}

#footer .container .copyright {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 200px;
  color: #6cc7ff;
}

@media (max-width: 1199px) {
  #footer .container .logo a {
    font-size: 14px;
    line-height: 16px;
  }

  #footer .container .tel b {
    font-size: 20px;
  }

  #footer .container .copyright {
    left: 130px;
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  #footer {
    margin-top: 30px;
    padding: 30px 0;
  }

  #footer .container .logo {
    display: none;
  }

  #footer .container .tel {
    position: static;
    text-align: center;
    transform: none;
  }

  #footer .container .tel b {
    font-size: 21px;
  }

  #footer .container .copyright {
    position: static;
    text-align: center;
    transform: none;
    margin-bottom: 26px;
  }

  #footer .container .copyright br {
    display: none;
  }
}
#form form .row::before,
#form form .row::after {
  content: '';
  display: table;
}

#form form .row::after {
  clear: both;
}

#form {
  background: #f8f4ea;
  padding: 30px;
  border-radius: 3px;
}

#form .head {
  font-size: 0;
  margin-bottom: 34px;
}

#form .head figure {
  display: inline-block;
}

#form .head figure img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

#form .head > div {
  display: inline-block;
  vertical-align: middle;
  padding-left: 15px;
  width: calc(100% - 130px);
}

#form .head .subtitle {
  margin-bottom: 0;
}

#form form .row {
  margin: 0 -15px 36px -15px;
}

#form form .row .col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

#form form textarea {
  margin-bottom: 13px;
}

#form form .btn {
  width: 178px;
}

#form.gray {
  background: #f7f9fa;
}

@media (max-width: 767px) {
  #form {
    padding: 30px 15px;
    margin: 0 -15px;
  }

  #form .head figure {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }

  #form .head > div {
    display: block;
    width: auto;
    padding-left: 0;
    text-align: center;
  }

  #form form .row {
    margin: 0 0 20px 0;
  }

  #form form .row .col {
    width: auto;
    padding: 0;
    float: none;
    margin-bottom: 20px;
  }

  #form form .row .col:last-child {
    margin-bottom: 0;
  }

  #form form .btn {
    width: 100%;
  }
}
#fotos {
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
}

#fotos .title {
  margin-bottom: 30px;
}

#fotos .row {
  margin: 0 -15px;
}

#fotos a {
  display: block;
  padding: 0 15px;
  text-align: center;
}

#fotos a img {
  border-radius: 3px;
  width: 100%;
}

#fotos .arr-prev,
#fotos .arr-next {
  position: absolute;
  top: 0;
}

#fotos .arr-prev {
  right: 41px;
}

#fotos .arr-next {
  right: 0;
}

#fotos:last-child {
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  #fotos .row {
    margin: 0 -9px;
  }

  #fotos a {
    padding: 0 9px;
  }
}

@media (max-width: 767px) {
  #fotos {
    text-align: center;
    margin-bottom: 30px;
  }

  #fotos .arr-prev,
  #fotos .arr-next {
    top: auto;
    bottom: 13px;
  }

  #fotos .arr-prev {
    right: auto;
    left: 13px;
  }

  #fotos .arr-next {
    right: 13px;
  }
}
.free-consult {
  background: #f7f9fa;
  padding: 30px 36px;
  position: relative;
  margin-bottom: 50px;
}

.free-consult .subtitle {
  margin-bottom: 20px;
}

.free-consult form input {
  width: 198px;
  margin-right: 10px;
}

.free-consult form button {
  width: 178px;
}

.free-consult:before {
  position: absolute;
  content: "";
  width: 209px;
  height: 201px;
  background: url("../img/girl.png") no-repeat;
  bottom: 0;
  right: 22px;
}

.free-consult.view-2 {
  padding: 30px 0 30px 204px;
}

.free-consult.view-2 figure {
  position: absolute;
  top: 30px;
  left: 30px;
  text-align: center;
}

.free-consult.view-2 figure img {
  border-radius: 50%;
}

.free-consult.view-2:before {
  display: none;
}

@media (max-width: 1199px) {
  .free-consult {
    padding: 25px 0 25px 25px;
  }

  .free-consult form input {
    width: 164px;
  }

  .free-consult form button {
    width: 94px;
    padding: 0;
    overflow: hidden;
  }

  .free-consult:before {
    display: none;
  }

  .free-consult.view-2 {
    padding: 25px 15px;
  }

  .free-consult.view-2 figure {
    display: none;
  }
}

@media (max-width: 767px) {
  .free-consult {
    padding: 25px 15px;
    margin-bottom: 30px;
    text-align: center;
  }

  .free-consult form input {
    width: 100%;
    margin-bottom: 10px;
  }

  .free-consult form button {
    width: 100%;
  }
}
#header .container {
  position: relative;
  height: 130px;
}

#header .logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  line-height: 20px;
  width: 100px;
}

#header .logo a {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #191919;
  background: url("../img/logo.png") no-repeat left;
  padding-left: 70px;
  background-size: auto 60px;
  display: block;
}

#header .slogan {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 200px;
  padding-left: 24px;
  line-height: 20px;
  color: #8e9faf;
  border-left: 1px solid #f3f6f7;
  width: 195px;
}

#header .select-city {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 500px;
  width: 170px;
}

#header .select-city a {
  border: 1px solid #f3f6f7;
  border-radius: 5px;
  display: block;
  height: 41px;
  line-height: 39px;
  padding-left: 30px;
  text-decoration: none;
  color: #191919;
  background: url("../img/icon-map.png") no-repeat 9px center;
  position: relative;
}

#header .select-city a:before {
  position: absolute;
  content: "";
  width: 12px;
  height: 7px;
  background: url("../img/arr-down.png") no-repeat;
  top: 16px;
  right: 13px;
}

#header .btn {
  right: 250px;
  width: 220px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#header .tel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  text-align: right;
}

#header .tel b {
  font-size: 24px;
}

#header .tel a {
  font-size: 16px;
}

#header .btn-menu {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: none;
}

#header .btn-menu .icon-burger {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#header .btn-menu .icon-burger,
#header .btn-menu .icon-burger:before,
#header .btn-menu .icon-burger:after {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #f56149;
  border-radius: 3px;
  -webkit-transition-property: background-color, -webkit-transform;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  transition-property: background-color, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

#header .btn-menu .icon-burger:before,
#header .btn-menu .icon-burger:after {
  position: absolute;
  content: "";
}

#header .btn-menu .icon-burger:before {
  top: -8px;
}

#header .btn-menu .icon-burger:after {
  top: 8px;
}

#header .btn-menu.active .icon-burger {
  background-color: transparent;
}

#header .btn-menu.active .icon-burger:before {
  -webkit-transform: translateY(8px) rotate(45deg);
  -moz-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
}

#header .btn-menu.active .icon-burger:after {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -moz-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}

@media (max-width: 1199px) {
  #header .container {
    height: 103px;
  }

  #header .logo {
    line-height: 16px;
  }

  #header .logo a {
    font-size: 14px;
    background: none;
    padding-left: 0;
  }

  #header .slogan {
    font-size: 11px;
    line-height: 16px;
    left: 95px;
    padding-left: 10px;
    width: 140px;
  }

  #header .select-city {
    left: auto;
    right: 355px;
    width: 140px;
  }

  #header .btn {
    right: 170px;
    width: 170px;
    font-size: 12px;
  }

  #header .tel b {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  #header .tel b {
    font-size: 21px;
  }

  #header .btn-menu {
    display: block;
  }
}
.fancybox-enabled {
  overflow: hidden;
}

.fancybox-enabled body {
  overflow: visible;
  -ms-touch-action: none;
  touch-action: none;
}

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-container ~ .fancybox-container {
  z-index: 99992;
}

.fancybox-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0f0f11;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-container--ready .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  z-index: 99994;
  transition: opacity .2s;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  direction: ltr;
}

.fancybox-show-controls .fancybox-controls {
  opacity: 1;
}

.fancybox-infobar {
  display: none;
}

.fancybox-show-infobar .fancybox-infobar {
  display: inline-block;
  pointer-events: all;
}

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}

.fancybox-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  pointer-events: all;
}

.fancybox-show-buttons .fancybox-buttons {
  display: block;
}

.fancybox-slider-wrap {
  overflow: hidden;
  direction: ltr;
}

.fancybox-slider,
.fancybox-slider-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 99993;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

.fancybox-slide:before {
  content: "";
  height: 100%;
  width: 0;
}

.fancybox-slide:before,
.fancybox-slide > * {
  display: inline-block;
  vertical-align: middle;
}

.fancybox-slide > * {
  position: relative;
  padding: 24px;
  margin: 44px 0;
  border-width: 0;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}

.fancybox-slide--image {
  overflow: hidden;
}

.fancybox-slide--image:before {
  display: none;
}

.fancybox-content {
  display: inline-block;
  position: relative;
  margin: 44px auto;
  padding: 0;
  border: 0;
  width: 80%;
  height: calc(100% - 88px);
  vertical-align: middle;
  line-height: normal;
  text-align: left;
  white-space: normal;
  outline: none;
  font-size: 16px;
  font-family: Arial,sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video .fancybox-iframe {
  background: transparent;
}

.fancybox-placeholder {
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-image,
.fancybox-placeholder,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

.fancybox-image,
.fancybox-spaceball {
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  background: transparent;
  background-size: 100% 100%;
}

.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: zoom-out;
}

.fancybox-controls--canzoomIn .fancybox-placeholder {
  cursor: zoom-in;
}

.fancybox-controls--canGrab .fancybox-placeholder {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-controls--isGrabbing .fancybox-placeholder {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-error {
  position: absolute;
  margin: 0;
  padding: 40px;
  top: 50%;
  left: 50%;
  width: 380px;
  max-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  cursor: default;
}

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px Helvetica Neue,Helvetica,Arial,sans-serif;
}

.fancybox-close-small {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  font: 21px/1 Arial,Helvetica Neue,Helvetica,sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  cursor: pointer;
  background: #fff;
  transition: background .2s;
  box-sizing: border-box;
  z-index: 2;
}

.fancybox-close-small:focus {
  outline: 1px dotted #888;
}

.fancybox-slide--video .fancybox-close-small {
  top: -36px;
  right: -36px;
  background: transparent;
}

.fancybox-close-small:hover {
  color: #555;
  background: #eee;
}

.fancybox-caption-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 30px 0;
  z-index: 99998;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8));
  opacity: 0;
  transition: opacity .2s;
  pointer-events: none;
}

.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1;
}

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none;
}

.fancybox-caption a,
.fancybox-caption button {
  pointer-events: all;
}

.fancybox-caption a {
  color: #fff;
  text-decoration: underline;
}

.fancybox-button {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background: transparent;
  color: #fff;
  box-sizing: border-box;
  vertical-align: top;
  outline: none;
}

.fancybox-button--disabled {
  cursor: default;
  pointer-events: none;
}

.fancybox-button,
.fancybox-infobar__body {
  background: rgba(30, 30, 30, 0.6);
}

.fancybox-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.fancybox-button:after,
.fancybox-button:before {
  content: "";
  pointer-events: none;
  position: absolute;
  border-color: #fff;
  background-color: currentColor;
  color: currentColor;
  opacity: .9;
  box-sizing: border-box;
  display: inline-block;
}

.fancybox-button--disabled:after,
.fancybox-button--disabled:before {
  opacity: .5;
}

.fancybox-button--left:after {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.fancybox-button--left:after,
.fancybox-button--right:after {
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
}

.fancybox-button--right:after {
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--left {
  border-bottom-left-radius: 5px;
}

.fancybox-button--right {
  border-bottom-right-radius: 5px;
}

.fancybox-button--close {
  float: right;
}

.fancybox-button--close:after,
.fancybox-button--close:before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 16px;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
}

.fancybox-button--close:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.fancybox-loading {
  border: 6px solid rgba(99, 99, 99, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: a .8s infinite linear;
  animation: a .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999;
}

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fancybox-button--fullscreen:before {
  width: 15px;
  height: 11px;
  left: 15px;
  top: 16px;
  border: 2px solid;
  background: none;
}

.fancybox-button--play:before {
  top: 16px;
  left: 18px;
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px inset transparent;
  border-left: 10px solid;
  border-radius: 1px;
  background: transparent;
}

.fancybox-button--pause:before {
  top: 16px;
  left: 18px;
  width: 7px;
  height: 11px;
  border-style: solid;
  border-width: 0 2px;
  background: transparent;
}

.fancybox-button--thumbs span {
  font-size: 23px;
}

.fancybox-button--thumbs:before {
  top: 20px;
  left: 21px;
  width: 3px;
  height: 3px;
  box-shadow: 0 -4px 0,-4px -4px 0,4px -4px 0,inset 0 0 0 32px,-4px 0 0,4px 0 0,0 4px 0,-4px 4px 0,4px 4px 0;
}

.fancybox-container--thumbs .fancybox-caption-wrap,
.fancybox-container--thumbs .fancybox-controls,
.fancybox-container--thumbs .fancybox-slider-wrap {
  right: 220px;
}

.fancybox-thumbs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 220px;
  margin: 0;
  padding: 5px 5px 0 0;
  background: #fff;
  z-index: 99993;
  word-break: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.fancybox-thumbs > ul {
  list-style: none;
  position: absolute;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0;
}

.fancybox-thumbs > ul > li {
  float: left;
  overflow: hidden;
  max-width: 50%;
  padding: 0;
  margin: 0;
  width: 105px;
  height: 75px;
  position: relative;
  cursor: pointer;
  outline: none;
  border: 5px solid #fff;
  border-top-width: 0;
  border-right-width: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
}

li.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs > ul > li > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  max-height: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-thumbs > ul > li:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  border: 4px solid #4ea7f9;
  z-index: 99991;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
  opacity: 1;
}

@media (max-width: 800px) {
  .fancybox-controls {
    text-align: left;
  }

  .fancybox-button--left,
  .fancybox-button--right,
  .fancybox-buttons button:not(.fancybox-button--close) {
    display: none !important;
  }

  .fancybox-caption {
    padding: 20px 0;
    margin: 0;
  }

  .fancybox-thumbs {
    display: none !important;
  }

  .fancybox-container--thumbs .fancybox-caption-wrap,
  .fancybox-container--thumbs .fancybox-controls,
  .fancybox-container--thumbs .fancybox-slider-wrap {
    right: 0;
  }
}
.menu {
  background: #f8f4ea;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.menu h3 {
  margin-bottom: 0;
  line-height: 54px;
  padding: 0 25px;
  font-size: 18px;
}

.menu > ul > li > a {
  display: block;
  padding: 10px 25px;
  position: relative;
}

.menu > ul > li ul {
  padding-left: 32px;
  margin: 10px 0;
  display: none;
}

.menu > ul > li ul li {
  position: relative;
  padding-left: 12px;
  margin-bottom: 15px;
}

.menu > ul > li ul li a {
  font-size: 12px;
  text-transform: lowercase;
}

.menu > ul > li ul li.active a {
  text-decoration: none;
}

.menu > ul > li ul li:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  background: #185ea2;
  top: 8px;
  left: 0;
}

.menu > ul > li ul li:last-child {
  margin-bottom: 0;
}

.menu > ul > li:hover > a {
  background: #eee8d8;
}

.menu > ul > li.active > a {
  background: #eee8d8;
  text-decoration: none;
}

.menu > ul > li.active > a:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 12px;
  background: url("../img/arr-menu.png") no-repeat;
  right: 15px;
  top: 13px;
}

.menu > ul > li.dcjq-parent-li > a.dcjq-parent.active {
  background: #eee8d8;
  text-decoration: none;
}

.menu > ul > li.dcjq-parent-li > a.dcjq-parent.active:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 12px;
  background: url("../img/arr-menu.png") no-repeat;
  right: 15px;
  top: 13px;
}

.menu > ul > li.active ul {
  display: block !important;
}

.menu:last-child {
  margin-bottom: 0;
}

.menu#menu > ul > li ul {
  display: block;
}

@media (max-width: 767px) {
  .menu {
    margin: 0 -15px 1px -15px;
    padding-bottom: 0;
  }

  .menu h3 {
    text-align: left;
    line-height: 50px;
    color: #185ea2;
    font-weight: normal;
    position: relative;
    cursor: pointer;
  }

  .menu h3:before {
    position: absolute;
    content: "";
    width: 16px;
    height: 10px;
    background: url("../img/arr-down-m.png") no-repeat;
    right: 15px;
    top: 20px;
  }

  .menu > ul {
    display: none;
  }
}
#mobile-wrap {
  background: #0483d2;
  padding: 0 15px 20px 15px;
  display: none;
  margin-bottom: 20px;
}

#mobile-wrap #quick-search {
  background: #1096e9;
  padding: 10px 15px;
  margin: 0 -15px;
  margin-bottom: 0;
}

#mobile-wrap #nav {
  margin: 0 -15px 20px -15px;
}

#mobile-wrap #nav > ul > li {
  display: block;
}

#mobile-wrap #nav > ul > li > a {
  padding: 0 15px;
  text-align: left;
  font-size: 14px;
  position: relative;
}

#mobile-wrap #nav > ul > li > a.active {
  box-shadow: inset 2px 0 0 #f04124;
}

#mobile-wrap #nav > ul > li > a.active:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 16px;
  background: url("../img/arr-submneu-m.png") no-repeat;
  right: 15px;
  top: 12px;
}

#mobile-wrap #nav > ul > li.active > a,
#mobile-wrap #nav > ul > li:hover > a {
  box-shadow: inset 2px 0 0 #f04124;
}

#mobile-wrap #nav > ul > li.active > a:before,
#mobile-wrap #nav > ul > li:hover > a:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 16px;
  background: url("../img/arr-submneu-m.png") no-repeat;
  right: 15px;
  top: 12px;
}

#mobile-wrap .tel {
  color: #fff;
  margin-bottom: 16px;
}

#mobile-wrap .tel b {
  font-size: 20px;
}

#mobile-wrap .tel a {
  color: #fff;
  font-size: 12px;
}

#mobile-wrap .select-city {
  margin-bottom: 17px;
}

#mobile-wrap .select-city a {
  display: block;
  position: relative;
  padding: 0 13px;
  background: #fff;
  line-height: 41px;
  border-radius: 3px;
  text-decoration: none;
  color: #191919;
}

#mobile-wrap .select-city a:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 16px;
  background: url("../img/arr-city.png") no-repeat;
  top: 13px;
  right: 12px;
}

#mobile-wrap .btn {
  width: 100%;
}
#nav {
  background: #0483d2;
  position: relative;
  z-index: 100;
}

#nav > ul {
  text-align: center;
  font-size: 0;
}

#nav > ul > li {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

#nav > ul > li > a {
  display: block;
  padding: 0 31px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  line-height: 41px;
}

#nav > ul > li > a.active {
  background: #1096e9;
  box-shadow: 0 -2px 0 #f04124;
}

#nav > ul > li > ul {
  position: absolute;
  left: 0;
  top: 100%;
  background: #0483d2;
  width: 270px;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}

#nav > ul > li > ul > li {
  position: relative;
}

#nav > ul > li > ul > li > a {
  font-size: 14px;
  display: block;
  line-height: 36px;
  padding: 0 25px;
  color: #fff;
  position: relative;
}

#nav > ul > li > ul > li > a.active {
  background: #1096e9;
}

#nav > ul > li > ul > li > a.active:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 12px;
  background: url("../img/arr-submneu.png") no-repeat;
  right: 15px;
  top: 12px;
}

#nav > ul > li > ul > li > ul {
  position: absolute;
  top: 0;
  left: 100%;
  background: #1096e9;
  width: 270px;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}

#nav > ul > li > ul > li > ul li a {
  display: block;
  line-height: 36px;
  padding: 0 13px;
  text-transform: lowercase;
  color: #fff;
  font-size: 12px;
}

#nav > ul > li > ul > li > ul li:hover a {
  background: #0483d2;
}

#nav > ul > li > ul > li:hover > a {
  background: #1096e9;
}

#nav > ul > li > ul > li:hover > a:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 12px;
  background: url("../img/arr-submneu.png") no-repeat;
  right: 15px;
  top: 12px;
}

#nav > ul > li > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
}

#nav > ul > li:hover > a,
#nav > ul > li.active > a {
  background: #1096e9;
  box-shadow: 0 -2px 0 #f04124;
}

#nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1199px) {
  #nav > ul > li > a {
    padding: 0 10px;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  #nav > ul > li > ul {
    position: static;
    width: auto;
    transition: none;
    opacity: 1;
    visibility: visible;
  }

  #nav > ul > li > ul > li > ul {
    position: static;
    width: auto;
    transition: none;
    opacity: 1;
    visibility: visible;
  }

  #nav > ul > li > ul > li > ul li a {
    padding: 0 0 0 38px;
  }
}
#question .item::before,
#question .item::after {
  content: '';
  display: table;
}

#question .item::after {
  clear: both;
}

#question {
  margin-bottom: 50px;
  position: relative;
}

#question .item {
  font-size: 0;
  margin-bottom: 25px;
}

#question .item figure {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}

#question .item .item-body {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 70px);
  padding-left: 11px;
}

#question .item .item-body strong {
  font-size: 18px;
}

#question .item .item-body span {
  font-size: 14px;
}

#question .btn {
  width: 138px;
  margin-right: 25px;
}

#question:last-child {
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  #question .item .item-body strong {
    font-size: 14px;
  }

  #question .item .item-body span {
    font-size: 12px;
  }

  #question .btn {
    margin-right: 12px;
  }
}
#quick-search {
  padding: 25px 0 30px 0;
  margin-bottom: 40px;
  background: #f7f9fa url("../img/bg-quick-search.jpg") repeat-x bottom;
}

#quick-search form {
  position: relative;
  font-size: 0;
}

#quick-search form input {
  border: 1px solid #f3f6f7;
}

#quick-search form button {
  position: absolute;
  background: url("../img/icon-search.png") no-repeat;
  width: 16px;
  height: 16px;
  text-indent: -9999px;
  border: none;
  top: 12px;
  right: 13px;
}
#rel-items {
  position: relative;
}

#rel-items .link-all {
  position: absolute;
  top: 10px;
  right: 0;
}

#rel-items .row {
  margin: 0 -15px;
}

#rel-items .row .item {
  padding: 0 15px;
}

#rel-items .row .item figure {
  margin-bottom: 20px;
}

#rel-items .row .item .item-name {
  font-weight: bold;
  margin-bottom: 18px;
  font-size: 18px;
}

#rel-items .row .item a {
  display: inline-block;
  line-height: 20px;
}

@media (max-width: 767px) {
  #rel-items .link-all {
    display: none;
  }

  #rel-items .row {
    margin: 0;
  }

  #rel-items .row .item {
    padding: 0;
    text-align: center;
  }
}
#content #reviews .item-answer::before,
#content #reviews .item-answer::after {
  content: '';
  display: table;
}

#content #reviews .item-answer::after {
  clear: both;
}

#reviews {
  margin-bottom: 50px;
  position: relative;
}

#reviews .btn-scroll-form {
  position: absolute;
  top: 0;
  right: 0;
  width: 178px;
}

#reviews .wrap {
  background: #f8f4ea;
  padding: 20px 12px;
}

#reviews .item figure {
  font-size: 0;
  margin-bottom: 20px;
}

#reviews .item figure img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

#reviews .item .item-name {
  margin-bottom: 12px;
}

#reviews .item .item-name b {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

#reviews .item .item-name span {
  color: #8e9faf;
}

#reviews .item p {
  line-height: 20px;
}

#reviews .item p em {
  font-family: Georgia, "Century Schoolbook L", Serif;
  margin-bottom: 20px;
}

#reviews .item p:last-child {
  margin-bottom: 0;
}

#reviews .slider-reviews {
  margin-bottom: 22px;
}

#reviews .arr-prev {
  margin-right: 5px;
}

#content #reviews .title {
  margin-bottom: 25px;
}

#content #reviews .item {
  background: #f8f4ea;
  padding: 20px 20px 20px 103px;
  position: relative;
  border-radius: 3px;
  margin-bottom: 20px;
}

#content #reviews .item figure {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 71px;
}

#content #reviews .item:last-child {
  margin-bottom: 0;
}

#content #reviews .item-answer {
  margin-bottom: 20px;
}

#content #reviews .item-answer .author {
  float: left;
  padding-left: 20px;
  width: 198px;
  padding-right: 10px;
}

#content #reviews .item-answer .author figure {
  font-size: 0;
  margin-bottom: 10px;
}

#content #reviews .item-answer .author figure img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

#content #reviews .item-answer .author strong {
  font-size: 16px;
}

#content #reviews .item-answer .text {
  position: relative;
  background: #f7f9fa;
  margin-left: 198px;
  padding: 20px;
  font-family: Georgia, "Century Schoolbook L", Serif;
  font-style: italic;
  line-height: 20px;
  border-radius: 3px;
}

#content #reviews .item-answer .text:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 19px;
  background: url("../img/corner-answer.png") no-repeat;
  top: 32px;
  left: -10px;
}

#content #reviews .item-answer:last-child {
  margin-bottom: 0;
}

#content #reviews .item-answer-2 {
  padding: 20px 20px 20px 103px;
  margin-bottom: 20px;
  border: 1px solid #0483d2;
  position: relative;
  border-radius: 3px;
}

#content #reviews .item-answer-2 figure {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 71px;
}

#content #reviews .item-answer-2 figure img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

#content #reviews .item-answer-2 .author {
  margin-bottom: 14px;
}

#content #reviews .item-answer-2 .author strong {
  font-size: 16px;
}

#content #reviews .item-answer-2 p {
  font-family: Georgia, "Century Schoolbook L", Serif;
  font-style: italic;
}

#content #reviews .item-answer-2:before {
  position: absolute;
  content: "";
  width: 26px;
  height: 14px;
  background: url("../img/corner-answer-2.png") no-repeat;
  top: -15px;
  left: 40px;
  border-bottom: 2px solid #FFF;
}

@media (max-width: 1199px) {
  #reviews .item p {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  #reviews .btn-scroll-form {
    position: static;
    display: table;
    margin: -15px auto 20px auto;
  }

  #content #reviews .item {
    padding: 20px;
    text-align: center;
  }

  #content #reviews .item figure {
    position: static;
    width: auto;
  }

  #content #reviews .item-answer .author {
    padding: 20px;
    text-align: center;
    float: none;
    width: auto;
  }

  #content #reviews .item-answer .text {
    margin-left: 0;
  }

  #content #reviews .item-answer .text:before {
    top: -14px;
    margin-left: -5px;
    left: 50%;
    transform: rotate(90deg);
  }

  #content #reviews .item-answer-2 {
    padding: 20px;
    text-align: center;
  }

  #content #reviews .item-answer-2 figure {
    position: static;
    width: auto;
    margin-bottom: 20px;
  }
}
#specialists {
  position: relative;
}

#specialists .row {
  margin: 0 -10px;
}

#specialists .slick-slide {
  padding: 0 10px;
}

#specialists .slick-slide .item {
  background: #f7f9fa;
  margin-bottom: 40px;
  border-radius: 3px;
  text-align: center;
  padding-bottom: 20px;
}

#specialists .slick-slide .item figure {
  font-size: 0;
  margin-bottom: 15px;
}

#specialists .slick-slide .item figure img {
  width: 100%;
  border-radius: 3px;
}

#specialists .slick-slide .item .name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0 16px;
}

#specialists .slick-slide .item .status {
  margin-bottom: 10px;
  padding: 0 16px;
}

#specialists .slick-slide .item p {
  font-size: 12px;
  text-align: left;
  padding: 0 16px;
}

@media (max-width: 767px) {
  #specialists {
    text-align: center;
  }
}
#services {
  margin-bottom: 50px;
}

#services ul li {
  margin-bottom: 16px;
}

#services ul li a {
  font-size: 0;
  color: #499091;
}

#services ul li a figure {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  line-height: 40;
  text-align: center;
}

#services ul li a img {
  display: inline;
}

#services ul li a span {
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
  text-decoration: underline;
  width: calc(100% - 40px);
  padding-left: 5px;
  font-weight: 600;
}

#services ul li a:hover span {
  text-decoration: none;
}

#services ul li:last-child {
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  #services ul li a span {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  #services {
    text-align: center;
    margin-bottom: 30px;
  }

  #services ul {
    padding-left: 30px;
    text-align: left;
  }
}
/* Slider */

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}
#steps .slider-steps .item::before,
#steps .slider-steps .item::after {
  content: '';
  display: table;
}

#steps .slider-steps .item::after {
  clear: both;
}

#steps {
  background: #f8f4ea;
  padding: 30px;
}

#steps h3 {
  margin-bottom: 4px;
  font-size: 18px;
}

#steps .slider-steps .slick-slide > div {
  margin-bottom: 30px;
}

#steps .slider-steps .slick-slide > div:last-child {
  margin-bottom: 0;
}

#steps .slider-steps .item figure {
  float: left;
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 50%;
}

#steps .slider-steps .item .item-body {
  margin-left: 170px;
}

#steps .slider-steps .item .item-body .step {
  background: linear-gradient(to top, #e44b32, #f6634a);
  display: inline-block;
  border-radius: 3px;
  padding: 0 8px;
  line-height: 21px;
  margin-bottom: 3px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}

#steps .slider-steps .item .item-body .item-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}

#steps .slider-steps .item .item-body p {
  line-height: 20px;
}

#steps .arrs {
  display: none;
  font-size: 0;
  margin-top: 20px;
}

#steps .arrs a {
  margin: 0 4px;
}

@media (max-width: 1199px) {
  #steps .slider-steps .item .item-body .item-name {
    font-size: 16px;
  }

  #steps .slider-steps .item .item-body p {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  #steps {
    padding: 20px 15px;
    text-align: center;
    margin: 0 -15px -30px -15px;
  }

  #steps .slider-steps .item figure {
    display: none;
  }

  #steps .slider-steps .item .item-body {
    margin-left: 0;
  }

  #steps .arrs {
    display: block;
  }
}
.sticky-buttons {
  position: fixed;
  top: 30vh;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  border-radius: 10px 0 0 10px;
  background: #ededed;
  z-index: 200;
}

.sticky-buttons .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  text-decoration: none;
}

.sticky-buttons .item:last-child {
  margin-bottom: 0;
}

.sticky-buttons .item .item-icon {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: relative;
}

.sticky-buttons .item .item-icon img {
  display: block;
  transition: all .3s;
}

.sticky-buttons .item .item-icon img.on-hover {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sticky-buttons .item .item-text {
  font-size: 14px;
  line-height: 16px;
  color: #333;
  transition: all .3s;
  text-align: center;
}

.sticky-buttons .item:hover .item-icon img.on-hover {
  opacity: 1;
}

.sticky-buttons .item:hover .item-icon img.visible {
  opacity: 0;
}

@media screen and (min-width: 992px) {
  .sticky-buttons .item .item-text {
    padding: 5px 10px;
    border: 1px solid #499091;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    right: calc(100% + 17px);
    top: 50%;
    transform: translate(-20px, -50%);
    opacity: 0;
    visibility: hidden;
    min-width: 140px;
  }

  .sticky-buttons .item .item-text:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 7px;
    border-color: transparent transparent transparent #499091;
    position: absolute;
    left: 100%;
    top: calc(50% - 7px);
  }

  .sticky-buttons .item:hover .item-text {
    transform: translate(0, -50%);
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 991px) {
  .sticky-buttons {
    top: auto;
    bottom: 0;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: row;
    border-radius: 10px 10px 0 0;
    padding: 9px;
  }

  .sticky-buttons .item {
    margin-bottom: 0;
    padding: 0 8px;
    min-width: 110px;
  }

  .sticky-buttons .item:last-child {
    margin-bottom: 0;
  }

  .sticky-buttons .item .item-icon {
    margin-bottom: 3px;
    flex: none;
  }

  .sticky-buttons .item .item-text {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 12px;
  }
}

@media screen and (max-width: 575px) {
  .sticky-buttons {
    width: 100%;
    justify-content: space-around;
    left: 0;
    transform: translateX(0);
    border-radius: 0;
    padding: 8px;
  }

  .sticky-buttons .item {
    min-width: 95px;
    padding: 0 5px;
  }
}

@media screen and (max-width: 359px) {
  .sticky-buttons {
    padding: 7px;
  }

  .sticky-buttons .item .item-icon {
    width: 24px;
    height: 24px;
  }

  .sticky-buttons .item .item-icon img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
.team {
  margin-bottom: 50px;
  position: relative;
}

.team .title {
  margin-bottom: 30px;
}

.team .item {
  text-align: center;
  padding: 0 15px;
}

.team .item figure {
  font-size: 0;
  margin-bottom: 15px;
}

.team .item figure img {
  display: inline-block;
  border-radius: 50%;
  width: 130px;
  height: 130px;
}

.team .item .name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.team .slider-team {
  margin-bottom: 20px;
}

.team .arr-prev,
.team .arr-next {
  position: absolute;
  top: 0;
}

.team .arr-prev {
  right: 41px;
}

.team .arr-next {
  right: 0;
}

.team:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .team {
    text-align: center;
    margin-bottom: 30px;
  }

  .team .arr-prev,
  .team .arr-next {
    position: static;
  }

  .team .arr-prev {
    margin-right: 6px;
  }
}
#why ul::before,
#why ul::after {
  content: '';
  display: table;
}

#why ul::after {
  clear: both;
}

#why {
  margin-bottom: 50px;
}

#why ul {
  border-bottom: 1px solid #dfdfdf;
}

#why ul li {
  float: left;
  width: 25%;
  border-right: 1px solid #dfdfdf;
  text-align: center;
  height: 200px;
  padding-top: 45px;
}

#why ul li figure {
  font-size: 0;
  line-height: 60px;
  margin-bottom: 28px;
}

#why ul li p {
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
}

#why ul li:last-child {
  border-right: 0;
}

#why ul:last-child {
  border-bottom: 0;
}

@media (max-width: 1199px) {
  #why ul li {
    padding-top: 23px;
    height: 160px;
  }

  #why ul li figure {
    margin-bottom: 18px;
  }

  #why ul li p {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  #why {
    margin-bottom: 30px;
  }

  #why ul li {
    width: 50%;
    border-bottom: 1px solid #dfdfdf;
    padding: 11px 0;
    height: auto;
  }

  #why ul li:nth-child(even) {
    border-right: none;
  }

  #why ul li:nth-last-child(1),
  #why ul li:nth-last-child(2) {
    border-bottom: none;
  }

  #why ul li figure {
    margin-bottom: 11px;
  }

  #why ul li p {
    line-height: 16px;
  }
}
.window#window-city .window-inner .list-of-cities ul::before,
.window#window-calc .window-inner form::before,
.window#window-city .window-inner .list-of-cities ul::after,
.window#window-calc .window-inner form::after {
  content: '';
  display: table;
}

.window#window-city .window-inner .list-of-cities ul::after,
.window#window-calc .window-inner form::after {
  clear: both;
}

.window {
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 100px;
  display: none;
  z-index: 100;
}

.window .window-inner {
  margin: 0 auto;
  padding: 25px 30px;
  width: 300px;
  background: #fff;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 0 30px rgba(142, 159, 175, 0.35);
}

.window .window-inner form input,
.window .window-inner form textarea,
.window .window-inner form select {
  background: #f3f6f7;
  margin-bottom: 20px;
  color: #8e9faf;
}

.window .window-inner form .btn {
  width: 100%;
}

.window .window-inner .btn-close {
  position: absolute;
  background: url("../img/btn-close.png") no-repeat;
  width: 12px;
  height: 12px;
  top: 13px;
  right: 9px;
}

.window#window-city .window-inner {
  width: 731px;
}

.window#window-city .window-inner .title {
  margin-bottom: 20px;
}

.window#window-city .window-inner .list-of-cities {
  margin-bottom: 12px;
}

.window#window-city .window-inner .list-of-cities ul li {
  float: left;
  width: 25%;
  margin-bottom: 16px;
  background: url("../img/arr-li.png") no-repeat left;
  padding-left: 25px;
}

.window#window-city .window-inner .list-of-cities ul li a {
  text-decoration: none;
  color: #191919;
}

.window#window-city .window-inner .list-of-cities:last-child {
  margin-bottom: 0;
}

.window#window-city .window-inner form {
  padding-top: 25px;
  border-top: 1px solid #e6eaed;
  position: relative;
  padding-right: 211px;
  padding-left: 30px;
  margin: 0 -30px;
}

.window#window-city .window-inner form input {
  margin-bottom: 0;
}

.window#window-city .window-inner form .btn {
  position: absolute;
  top: 25px;
  right: 30px;
  width: 169px;
}

.window#window-calc .window-inner {
  width: 633px;
}

.window#window-calc .window-inner form {
  margin: 0 -15px -20px -15px;
}

.window#window-calc .window-inner form .col {
  width: 50%;
  float: left;
  padding: 0 15px;
}

@media (max-width: 767px) {
  .window#window-city .window-inner {
    width: 290px;
  }

  .window#window-city .window-inner .list-of-cities ul li {
    float: none;
    width: auto;
  }

  .window#window-city .window-inner form {
    margin: 0;
    padding: 0;
  }

  .window#window-city .window-inner form .btn {
    position: static;
    width: 100%;
    margin-top: 10px;
  }

  .window#window-calc .window-inner {
    width: 290px;
  }

  .window#window-calc .window-inner form {
    margin-bottom: 0;
  }

  .window#window-calc .window-inner form .col {
    float: none;
    width: auto;
    padding: 0;
  }

  .window#window-calc .window-inner form .col .hidden {
    display: none;
  }
}